:root {
  --background: #0f172a;
  --text-primary: #fefeff;
  --text-secondary: #8d9bb1;
  --background1: #1e283a;
  --background2: #141934;
  --border: solid 1px #141f30;
  --color: #000;
  --blue_color: #328eff;
  --Green_color: #71b618;
  --DarkGreen_color: #4ead5b;
  --light_Brown_color: #454444;
  --red_color: #e93322;
  --Yellow_color: #fff241;
  --Light_Yellow_color: #f8efad;
  --gray_color: #cacaca;
  --light_green: #52bd94;
  --light-gray: #f4f6fa;
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #063;
  --toastify-color-error: #063;
  --toastify-color-warning: var(--Green_color);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(var(--Green_color));
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --body-background-color: #e9e9d0;
  /* Used only for colored theme */
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Used when no type is provided */
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  /* // Used when no type is provided */
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --active_color_background: #d0f3e6;
  --active-color_text: #52bd94;
  --inactive_color_background: #fbe6e6;
  --inactive_color_text: #ee2e2e;
  --light-blue: #b3d5ff;
  --table_header: #4e4c4d;
  --Gray_table_header: #edeff5;
  --green-border: #429777;

  /* setting */
  --text-label: #6d726f;
  --background-alert: #e3e8e4;
  --background-green: #ddffee;
  --text-green: #199f5c;
  --icon-fill: #8f95b2;
  --icon-fill1: #ea6041;
  --box_shadow: rgba(0, 0, 0, 0.12);
  --btn-border: #d8dae5;
  --status-green: #52bd94;
  --status-blue: #328eff;
  --status-red: #ee2e2e;
  --Gray_table_header: #edeff5;
  --icon-fill2: #cecece;
  --icon-fill3: #a7aba9;
  --text-lightgrey: #b4b5b7;
  --bg-modal-color: #424242;
  --dark_grey: #666666;
}

.App {
  text-align: center;
}
body {
  background-color: var(--body-background-color) !important;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #09d3ac;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.bold {
  font-family: "InterBold" !important;
}

.black {
  font-family: "InterBlack" !important;
}

.extrabold {
  font-family: "InterExtraBold" !important;
}

.extraLight {
  font-family: "InterExtraLight" !important;
}

.light {
  font-family: "InterLight" !important;
}

.medium {
  font-family: "InterMedium" !important;
}

.regular {
  font-family: "InterRegular" !important;
}

.semibold {
  font-family: "InterSemiBold" !important;
}

.thin {
  font-family: "InterThin" !important;
}

.inputoutline {
  border-radius: 8px;
  border: 1px solid #f2f4f5;
}

.inputoutline input {
  border: none !important;
  outline: none !important;
  width: 90%;
  background-color: transparent;
}

.datepickerbtn_report {
  border: none !important;
  outline: none !important;
  background: transparent !important;
  color: #67757c !important;
}

.tableWithPagination {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.tableWithOutPagination {
  border-radius: 6px;
}

.mandatory {
  color: red;
}

input:focus {
  outline: none;
}

input {
  font-family: "InterRegular";
}

input::placeholder {
  font-family: "InterRegular";
}
input,
label,
textarea {
  font-family: "InterMedium" !important;
}

button {
  font-family: "InterBold";
}

.Table_font {
  font-family: "InterMedium";
  font-size: 15px;
  color: #000;
}
span {
  font-family: "InterMedium";
  font-size: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "InterMedium" !important;
  /* font-weight: bold !important; */
  color: #000;
}
p {
  margin: 0 !important;
  font-size: 15px;
  font-family: "InterMedium";
  color: #000;
  text-align: left;
}

.background_image {
  background: url("./Assets/images/background.png");
  /* background-image: url("./Assets/images/Authentication.jpg"); */
  background-position: center;
  background-size: cover;
  /* background-color: rgb(0, 0, 0,0.5); */
}

.image_container1 {
  height: 100% !important;
  background-color: #e7efeb;
}
.image_container {
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.AuthHeight {
  height: 100vh;
}
@media only screen and (max-width: 600px) {
  .AuthHeight {
    height: 60vh;
  }
}
.fixedColor:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #fff241 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Card_image {
  background-image: url("./Assets/images/DashBoard_Card.svg");
  background-position: center;
  background-size: cover;
}
.Card_image2 {
  background-image: url("./Assets/images/Card2.png");
  background-position: center;
  background-size: cover;
}
.Card_image3 {
  background-image: url("./Assets/images/Card3.png");
  background-position: center;
  background-size: cover;
}

.rdrDateRangePickerWrapper .rdrDateDisplayWrapper .rdrDateDisplay {
  font-size: 16px;
  font-family: "InterBlack", sans-serif !important;
  color: #063;
}

/* Example targeting month text */
.rdrDateRangePickerWrapper .rdrMonthAndYearWrapper .rdrMonthAndYearPickers {
  font-size: 16px;
  font-family: "InterBlack", sans-serif !important;
  font-weight: bold !important;
}

.input_bg {
  background: #ffff;
  padding: 1%;
  border-radius: 0.5em;
  border: 1px solid #333;
  color: #000;
}

textarea {
  color: #000;
  font-family: "InterRegular" !important;
}

textarea::placeholder {
  color: #000 !important;
  font-family: "InterRegular" !important;
}

.Textarea {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  color: #000;
  resize: none;
  background: transparent;
  border: none;
  outline: none;
}

.ActiveBtn {
  border-bottom: 3px solid #666666 !important;
  border-radius: 0 !important;
}

.invoiceTabletable {
  font-family: "InterMedium";
  border-collapse: collapse;
  width: 100%;
}

.invoiceTabletd {
  border: 1px solid #bfbfbf !important;
  text-align: left;
  padding: 8px;
}

.invoiceTableth {
  background-color: #f2f4f5;
  border-left: 1px solid #bfbfbf !important;
  text-align: left;
  padding: 8px;
}

/* .css-1ps6pg7-MuiPaper-root {
  border-radius: 8px !important;
}
.MuiPaper-root {
  border-radius: 8px !important;
} */

.sideBarButton {
  font-family: "InterMedium" !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #656565 !important;
}

.sideBarButton:hover {
  background-color: hsla(56, 100%, 63%, 0.45) !important;
  color: #000 !important;
  width: 100%;
}

.ActivesideBarButton {
  background-color: hsla(56, 100%, 63%, 0.45) !important;
  color: #000 !important;
  width: 100%;
  font-family: "InterMedium" !important;
  font-size: 16px !important;
  text-align: left !important;
}

.roundedbox {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.roundedboxleft {
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.SendBtn {
  background-color: "#FFF9A6"  !important;
  border-radius: 50% !important;
}

.profileinputboxparent{
  width: 100%;
  color: #000;
  border:1px solid #ACB0B1;
  border-radius: 8px;
  outline: none;
  font-family: "InterRegular";
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 1.4%;
}