@font-face {
  font-family: "InterBold";
  src: local("InterBold"),
    url("./Assets/fonts/InterFonts/InterBold.ttf") format("truetype");
}
@font-face {
  font-family: "InterBlack";
  src: local("InterBlack"),
    url("./Assets/fonts/InterFonts/InterBlack.ttf") format("truetype");
}
@font-face {
  font-family: "InterExtraBold";
  src: local("InterExtraBold"),
    url("./Assets/fonts/InterFonts/InterExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "InterExtraLight";
  src: local("InterExtraLight"),
    url("./Assets/fonts/InterFonts/InterExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "InterLight";
  src: local("InterLight"),
    url("./Assets/fonts/InterFonts/InterLight.ttf") format("truetype");
}
@font-face {
  font-family: "InterMedium";
  src: local("InterMedium"),
    url("./Assets/fonts/InterFonts/InterMedium.ttf") format("truetype");
}
@font-face {
  font-family: "InterRegular";
  src: local("InterRegular"),
    url("./Assets/fonts/InterFonts/InterRegular.ttf") format("truetype");
}
@font-face {
  font-family: "InterSemiBold";
  src: local("InterSemiBold"),
    url("./Assets/fonts/InterFonts/InterSemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "InterThin";
  src: local("InterThin"),
    url("./Assets/fonts/InterFonts/InterThin.ttf") format("truetype");
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  body {
    font-size: 20px !important;
  }
  p {
    font-size: 20px !important;
  }
}
body {
  margin: 0;
  width: 100%;
  background-color: #E9E9D0;
  color: #000;
  font-family: "Inter" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

code {
  font-family: Inter;
}
